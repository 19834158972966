import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { editPBX } from "../../store/features/pbxSlice";
import PropTypes from "prop-types";
import axios from "axios";
import {
  setApiDataExt,
  setApiDataPattern,
  setApiDataTrunks,
} from "../../store/features/apiDataSlice";

/*PbxDataForCalls es un componente que permite gestionar los datos relacionados con una PBX
para realizar llamadas. El componente recupera los datos actuales del PBX desde el estado global
 usando useSelector y los muestra en un formulario controlado por Formik. El formulario permite 
 al usuario ingresar o actualizar los datos de la extensión, la ruta, y el trunk (troncal). 
 También valida que los campos obligatorios tengan valores numéricos donde corresponde.
 extension: Número de extensión del PBX.
ruta: Número de la ruta asociada.
trunk: Nombre de la troncal (trunk).
 */

export default function PbxDataForCalls() {
  const dispatch = useDispatch();
  const pbx = useSelector((state) => state.pbx.value);
  const apiData = useSelector((state) => state.apiData.value);
  const [formData, setFormData] = useState({
    extension: "",
    ruta: "",
    trunkName: "",
  });

  const [apiext, setApiExt] = useState([]);
  const [apiRuta, setApiRuta] = useState([]);
  const [apiTrunk, setApiTrunk] = useState([]);

  //Rellenado de valores guardados
  useEffect(() => {
    const { extension, ruta, trunk } = pbx;
    setFormData({ extension, ruta, trunk });
  }, [pbx]);

  //Rellenado de valores guardados
  useEffect(() => {
    if (apiData.apiDataExt) {
      setApiExt(apiData.apiDataExt);
    }
    if (apiData.apiDataPattern) {
      setApiRuta(apiData.apiDataPattern);
    }
    if (apiData.apiDataTrunks) {
      setApiTrunk(apiData.apiDataTrunks);
    }
  }, []);

  useEffect(() => {
    if (apiext && apiext.length > 0) {
      dispatch(setApiDataExt(apiext));
    }
  }, [apiext]);

  useEffect(() => {
    if (apiRuta && apiRuta.length > 0) {
      dispatch(setApiDataPattern(apiRuta));
    }
  }, [apiRuta]);

  useEffect(() => {
    if (apiTrunk && apiTrunk.length > 0) {
      dispatch(setApiDataTrunks(apiTrunk));
    }
  }, [apiTrunk]);

  //Conseguir Extensiones
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (pbx.token !== null && pbx.token !== "") {
          let api;
          if (pbx.model === "S-Series") {
            api = pbx.domain + "/api/v2.0.0/extension/list?token=" + pbx.token;
            const response = await axios.post(api);
            if (response.data.status === "Success") {
              setApiExt(
                response.data.extlist.map((ext) => {
                  return ext.number;
                })
              );
            }
          } else {
            api =
              pbx.domain +
              "/openapi/v1.0/extension/list?access_token=" +
              pbx.token;
            const response = await axios.get(api);
            if (response.data.errmsg === "SUCCESS") {
              setApiExt(
                response.data.data.map((ext) => {
                  return ext.number;
                })
              );
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [pbx]);

  // Conseguir Rutas
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (pbx.token !== null && pbx.token !== "") {
          let api;
          if (pbx.model === "S-Series") {
            api = pbx.domain + "/api/v2.0.0/outroute/query?token=" + pbx.token;
            const response = await axios.post(api, { id: "all" });
            if (response.data.status === "Success") {
              setApiRuta(
                response.data.outroutes.flatMap((route) => {
                  return route.prefix.map((patron) => {
                    // Manejar caso con corchetes
                    const beforeBrackets = patron.split("[")[0];
                    // Extraer todos los números y el símbolo '+'
                    const num = beforeBrackets.match(/[+\d]+/g)?.join("") || "";
                    return { patron, num };
                  });
                })
              );
            }
          } else {
            api =
              pbx.domain +
              "/openapi/v1.0/outbound_route/list?access_token=" +
              pbx.token;
            const response = await axios.get(api);
            if (response.data.errmsg === "SUCCESS") {
              setApiRuta(
                response.data.data.flatMap((route) => {
                  return route.dial_pattern.map((patron) => {
                    // Manejar caso con corchetes
                    const beforeBrackets = patron.split("[")[0];
                    // Extraer todos los números y el símbolo '+'
                    const num = beforeBrackets.match(/[+\d]+/g)?.join("") || "";
                    return { patron, num };
                  });
                })
              );
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [pbx]);

  //Conseguir Troncales
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (pbx.token !== null && pbx.token !== "") {
          let api;
          if (pbx.model === "S-Series") {
            api = pbx.domain + "/api/v2.0.0/trunk/list?token=" + pbx.token;
            const response = await axios.post(api);
            if (response.data.status === "Success") {
              setApiTrunk(
                response.data.trunklist.map((ext) => {
                  return ext.trunkname;
                })
              );
            }
          } else {
            api =
              pbx.domain + "/openapi/v1.0/trunk/list?access_token=" + pbx.token;
            const response = await axios.get(api);
            if (response.data.errmsg === "SUCCESS") {
              setApiTrunk(
                response.data.data.map((ext) => {
                  return ext.name;
                })
              );
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [pbx]);

  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      validate={(values) => {
        const errors = {};
        if (!values.extension) {
          errors.extension = "Required Field";
        } else if (!/^[0-9]+$/.test(values.extension)) {
          errors.extension = "The value of the extension must be numeric";
        }
        if (!values.ruta) {
          errors.ruta = "Required Field";
        } else if (!/^[0-9]+$/.test(values.ruta)) {
          errors.ruta =
            "The value of the route must be numeric. Consider the '+' symbol in the contact number.";
        }
        if (!values.trunk) {
          errors.trunk = "Required Field";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          dispatch(editPBX(values));
          swal("Data set correctly", "", "success");
        } catch (e) {
          swal("Error", "An error occurred, please try again later", "error");
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Extention Number</Form.Label>
                <Form.Control
                  as="select"
                  name="extension"
                  onChange={(e) => setFieldValue("extension", e.target.value)}
                  onBlur={handleBlur}
                  value={values.extension}
                  isValid={touched.extension && !errors.extension}
                  isInvalid={!!errors.extension}
                >
                  <option value="">Choose An Option</option>
                  {apiext.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback>Rigth</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.extension}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Number of the route</Form.Label>
                <Form.Control
                  as="select"
                  name="ruta"
                  onChange={(e) => setFieldValue("ruta", e.target.value)}
                  onBlur={handleBlur}
                  value={values.ruta}
                  isValid={touched.ruta && !errors.ruta}
                  isInvalid={!!errors.ruta}
                >
                  <option value="">Choose an Option</option>
                  {apiRuta.map((item, index) => (
                    <option key={index} value={item.num}>
                      {item.patron}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback>Rigth</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.ruta}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Name of Trunk</Form.Label>
                <Form.Control
                  as="select"
                  name="trunk"
                  onChange={(e) => setFieldValue("trunk", e.target.value)}
                  onBlur={handleBlur}
                  value={values.trunk}
                  isValid={touched.trunk && !errors.trunk}
                  isInvalid={!!errors.trunk}
                >
                  <option value="">Choose an Option</option>
                  {apiTrunk.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback>Rigth</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.trunk}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </Form>
      )}
    </Formik>
  );
}

PbxDataForCalls.propTypes = {
  initialData: PropTypes.shape({
    extension: PropTypes.string,
    ruta: PropTypes.string,
    trunk: PropTypes.string,
  }),
};

PbxDataForCalls.defaultProps = {
  initialData: {
    extension: "",
    ruta: "",
    trunk: "",
  },
};
