import { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import queueIcon from "../../images/chatbot.png";
import { Stack } from "react-bootstrap";
import { useSelector } from "react-redux";

/*El componente QueueList muestra una lista de colas de PBX (basadas en el modelo P-Series o S-Series), que permite al usuario seleccionar una de ellas. 
Utiliza efectos para cargar la lista de colas desde la API de la PBX y resaltar la cola seleccionada.

setQueueSelected: Función que se invoca para actualizar la cola seleccionada.
setQueueSelectedName: Función que se invoca para actualizar el nombre de la cola seleccionada.
queueNumber: Número de la cola seleccionada inicialmente, que puede ser un número o cadena.
*/

function QueueList({ setQueueSelected, setQueueSelectedName, queueNumber }) {
  const [queues, setQueues] = useState(null);
  const [queueList, setQueueList] = useState([]);
  const [indexQChecked, setIndexQChecked] = useState();
  const queueNumberGet = useRef(queueNumber);
  const pbx = useSelector((state) => state.pbx.value);
  useEffect(() => {
    queueNumberGet.current = queueNumber;
  }, [queueNumber]);

  // Efecto para cargar datos iniciales
  useEffect(() => {
    const fetchData = async () => {
      try {
        let api =
          pbx.domain + "/openapi/v1.0/queue/list?access_token=" + pbx.token;
        if (pbx.model === "S-Series") {
          api = pbx.domain + "/api/v2.0.0/queue/query?token=" + pbx.token;
          const result = await axios.post(api, { number: "all" });
          if (result.data.status === "Success") {
            setQueues(result.data.queues);
          }
        } else {
          const result = await axios.get(api);
          if (result.data.errmsg === "SUCCESS") {
            setQueues(result.data.queue_list);
          }
        }
      } catch (error) {
        console.error("Hubo un error:", error.message);
      }
    };

    fetchData();
  }, [pbx]);

  // Efecto para configurar ivrList y seleccionar el índice
  useEffect(() => {
    console.log(indexQChecked);
    if (queues) {
      setQueueList(queues);
      const index = queues.findIndex(
        (item) =>
          item.number === queueNumberGet.current?.toString() ||
          item.queuenumber === queueNumberGet.current?.toString()
      );
      if (index !== -1 && queueNumber) {
        setIndexQChecked(index);
      }
    }
    console.log(indexQChecked);
  }, [queues, pbx.model]);

  const handleRadioChange = useCallback(
    (index, number, name) => {
      setIndexQChecked(index);
      setQueueSelected(number);
      setQueueSelectedName(name);
    },
    [setQueueSelected, setQueueSelectedName]
  );

  return (
    <Stack
      direction="horizontal"
      style={{ overflowY: "auto", whiteSpace: "nowrap" }}
    >
      {queueList &&
        queueList.length > 0 &&
        queueList.map((item, index) => (
          <label
            className="text-center ml-5 mb-0 mt-1"
            key={pbx.model === "P-Series" ? item.number : item.number}
          >
            <input
              type="radio"
              name="ivr"
              value={pbx.model === "P-Series" ? item.number : item.number}
              checked={indexQChecked === index}
              onChange={() =>
                handleRadioChange(
                  index,
                  pbx.model === "P-Series" ? item.number : item.number,
                  pbx.model === "P-Series"
                    ? item.name
                    : item.ivrname
                    ? item.ivrname
                    : item.queuename
                )
              }
              required
            />
            <img
              src={queueIcon}
              alt={pbx.model === "P-Series" ? item.name : item.ivrname}
            />
            <p>{item.name || item.ivrname}</p>
          </label>
        ))}
    </Stack>
  );
}
QueueList.propTypes = {
  setQueueSelected: PropTypes.func.isRequired,
  setQueueSelectedName: PropTypes.func.isRequired,
  queueNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

QueueList.defaultProps = {
  queueNumber: null,
};
export default QueueList;
