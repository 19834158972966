import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setTokens } from "../store/features/pbxSlice.js"; // Importa tu slice de PBX
/*El hook useRefreshPBXToken gestiona la actualización automática de un token de autenticación de PBX. 
Usa un intervalo de actualización para verificar si el token está por expirar y lo renueva cuando sea necesario. Además, 
actualiza el estado en Redux con los nuevos tokens recibidos.

refreshInterval: Define el intervalo en milisegundos (por defecto 15 minutos) antes de que el token sea refrescado.
 */
const useRefreshPBXToken = (refreshInterval = 15 * 60 * 1000) => {
  // Intervalo de 15 minutos
  const dispatch = useDispatch();
  const pbx = useSelector((state) => state.pbx.value);
  console.log("refresh interval:", refreshInterval);

  // Función que refresca los tokens
  const refreshPBXToken = async () => {
    console.log("Intentando refrescar el token de la PBX...");
    let api = pbx.domain + "/openapi/v1.0/refresh_token";

    let body = {
      "refresh_token": pbx.refreshToken,
    };
    if (pbx.model === "S-Series") {
      api = pbx.domain + "/api/v2.0.0/heartbeat?token=" + pbx.refreshToken;
      body = {};
    }

    try {
      const response = await fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: pbx.model === "S-Series" ? null : JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Datos de respuesta:", data);

        if (data.errcode === 0 || data.status === "Success") {
          // Actualizar el estado de Redux con los nuevos tokens
          if (pbx.model === "P-Series") {
            dispatch(
              setTokens({
                token: data.access_token,
                refreshToken: data.refresh_token,
              })
            );
          }

          // Almacenar los tokens y la fecha de expiración en sessionStorage o localStorage
          //   sessionStorage.setItem("pbxToken", data.access_token);
          //   sessionStorage.setItem("pbxRefreshToken", data.refresh_token);
          //   sessionStorage.setItem("pbxTokenExpiry", Date.now() + refreshInterval); // Tiempo de expiración
          //   console.log("Nuevo access token y refresh token almacenados correctamente.");
        } else {
          console.error("Error en la respuesta de la PBX:", data.errmsg);
        }
      } else {
        console.error("Error al hacer la solicitud:", response.statusText);
      }
    } catch (error) {
      console.error("Error al intentar refrescar el token en la PBX:", error);
    }
  };

  useEffect(() => {
    // Obtener el refresh token almacenado en sessionStorage o Redux
    const refreshToken = pbx.refreshToken;
    const tokenExpiry = pbx.token;

    console.log("Refresh Token almacenado en sessionStorage:", refreshToken);
    console.log(
      "Tiempo de expiración almacenado en sessionStorage:",
      tokenExpiry
    );

    // Si no hay refresh token o fecha de expiración, no hacemos nada
    if (!refreshToken || !tokenExpiry) {
      console.log("No hay refresh token o expiración del token.");
      return;
    }

    // Verificar si el token ya ha expirado
    const timeLeft = tokenExpiry - Date.now();
    console.log("Tiempo restante para que expire el token:", timeLeft);

    if (timeLeft > 0) {
      // Programar el refresco cuando el token esté cerca de expirar
      const timeoutId = setTimeout(() => {
        refreshPBXToken(refreshToken);
      }, timeLeft);

      // Limpiar el timeout cuando el componente se desmonta
      return () => clearTimeout(timeoutId);
    } else {
      // Si el token ya expiró, refrescarlo inmediatamente
      console.log("El token ya ha expirado, intentando refrescarlo...");
      refreshPBXToken(refreshToken);
    }
  }, [dispatch, refreshInterval]);

  return null; // El hook no necesita renderizar nada
};
useRefreshPBXToken.propTypes = {
  refreshInterval: PropTypes.number,
};

useRefreshPBXToken.defaultProps = {
  refreshInterval: 15 * 60 * 1000, // 15 minutos
};

export default useRefreshPBXToken;
