import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import pbxImage from "../../images/pbxcloud_syp.jpg";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { setNewPBX, setTokens } from "../../store/features/pbxSlice";
import axios from "axios";

export default function RegistryPbxFrom() {
  const [pbxData, setPbxData] = useState();
  const [nUrl, setUrl] = useState("");
  const [nApi, setNApi] = useState("");
  const [nPassword, setNPassword] = useState("");
  const [pbxStatus, setPbxStatus] = useState(0);
  const [pbxModel, setPbxModel] = useState("P-Series");

  const dispatch = useDispatch();
  const pbx = useSelector((state) => state.pbx.value);

  useEffect(() => {
    setPbxData({
      url: pbx.domain,
      nombre_usuario: pbx.apiuser,
      contrasena: pbx.apipass,
      model: pbx.model,
      status: pbx.status,
    });
  }, [pbx]);

  useEffect(() => {
    if (pbxData !== undefined) {
      console.log("PBX:", pbxData);
      setUrl(pbxData.url);
      setNApi(pbxData.nombre_usuario);
      setNPassword(pbxData.contrasena);
      setPbxModel(pbxData.model);
      setPbxStatus(pbxData.status);
    }
  }, [pbxData]);

  return (
    <Formik
      initialValues={{ url: nUrl, nombre_usuario: nApi, contrasena: nPassword }}
      validate={() => {
        const errors = {};
        if (!nUrl) {
          errors.url = "Required Field";
        } else if (
          !/^(http(s)?:\/\/)(([a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6})|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/gi.test(
            nUrl
          )
        ) {
          errors.url = "Invalid Url Format";
        }
        if (!nApi) {
          errors.nombre_usuario = "Required Field";
        }
        if (!nPassword) {
          errors.contrasena = "Required Field";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        if (pbxStatus === 1) {
          swal(
            "You're Connected",
            "The connection is already established.",
            "info"
          );
          setSubmitting(false);
          return;
        }

        try {
          var api = "openapi/v1.0/get_token";
          if (pbxModel === "S-Series") {
            console.log("S-Series!");
            api = "api/v2.0.0/login";
          }
          var consul = nUrl + "/" + api;

          axios
            .post(consul, {
              username: nApi,
              password: nPassword,
            })
            .then((response) => {
              if (
                response.data.errmsg === "SUCCESS" ||
                response.data.status === "Success"
              ) {
                setPbxData(response.data);
                console.log("Respuesta");
                console.log(response);
                console.log(response.data);
                setPbxStatus(1);
                dispatch(
                  setNewPBX({
                    domain: nUrl,
                    apiuser: nApi,
                    apipass: nPassword,
                    model: pbxModel,
                    status: 1,
                  })
                );
                dispatch(
                  setTokens({
                    token: response.data.access_token,
                    refreshToken: response.data.refresh_token,
                  })
                );
                swal(
                  "Successful registration",
                  "Successfully connected ",
                  "success"
                );
              } else {
                swal(
                  "Register Error",
                  response.data.errmsg
                    ? response.data.errmsg
                    : response.data.erno,
                  "error"
                );
              }
            })
            .catch((err) => {
              swal(
                "Register Error",
                err.response?.data ? err.response.data : "No connection",
                "error"
              );
            });
        } catch (error) {
          console.log("Ha ocurrido algo...", error);
        }
      }}
    >
      {({ errors, touched, handleBlur, handleSubmit, isSubmitting }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="row">
                {/* Cambiar el tamaño de las columnas del formulario */}
                <div className="col-md-5">
                  <Form.Group>
                    <Form.Label>PBX Model</Form.Label>
                    <Form.Select
                      name="modelo_pbx" // Agregar un atributo `name`
                      size="lg"
                      className={`form-control ${
                        touched.modelo_pbx && errors.modelo_pbx
                          ? "is-invalid"
                          : ""
                      }`}
                      onChange={(e) => {
                        setPbxModel(e.target.value); // También actualizar el estado local si es necesario
                        console.log('Cambiando modelo', pbxModel);
                      }}
                      onBlur={handleBlur} // Formik usará este evento
                      value={pbxModel} // Controlado por estado
                    >
                      <option value="S-Series">
                        {"S-Series (Only Cloud)"}
                      </option>
                      <option value="P-Series">
                        {"P-Series (On Premise / Cloud)"}
                      </option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>URL or IP Address</Form.Label>
                    <Form.Control
                      required
                      type="Text"
                      name="url"
                      onChange={(e) => setUrl(e.target.value)}
                      onBlur={handleBlur}
                      value={nUrl}
                      isValid={touched.url && !errors.url}
                      isInvalid={!!errors.url}
                    />
                    <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.url}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>API Username</Form.Label>
                    <Form.Control
                      required
                      type="Text"
                      name="nombre_usuario"
                      onChange={(e) => setNApi(e.target.value)}
                      onBlur={handleBlur}
                      value={nApi}
                      isValid={touched.nombre_usuario && !errors.nombre_usuario}
                      isInvalid={!!errors.nombre_usuario}
                    />
                    <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.nombre_usuario}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>API Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      name="contrasena"
                      onChange={(e) => setNPassword(e.target.value)}
                      onBlur={handleBlur}
                      value={nPassword}
                      isValid={touched.contrasena && !errors.contrasena}
                      isInvalid={!!errors.contrasena}
                    />
                    <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.contrasena}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-7 d-flex align-items-center">
                  <img
                    src={pbxImage}
                    style={{ width: "300px" }} // Ajustar el tamaño de la imagen
                    alt="yeastarSerieS"
                  />
                  <div
                    className="ml-4 border border-dark p-3 text-center"
                    style={{
                      border: "2px solid black", // Borde más grueso
                      color: "black", // Asegura que el texto del borde sea más visible
                      flex: 1,
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2em",
                        marginBottom: "0.5em",
                      }}
                    >
                      You don’t have a PBX?
                    </p>
                    <p>Get a free 30-day trial!</p>
                    <Button
                      variant="warning"
                      href="https://www.yeastar.com/p-series-free-trial/?referralId=603874803526407561"
                      target="_blank" // Abre el enlace en una nueva pestaña
                      rel="noopener noreferrer" // Añade seguridad al nuevo enlace
                    >
                      Free Trial
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <small>
                    <span
                      className={`dot dot-lg ${
                        pbxStatus === 1
                          ? "bg-success"
                          : pbxStatus === 2
                          ? "bg-warning"
                          : "bg-danger"
                      } mr-1`}
                    ></span>
                    {pbxStatus === 1
                      ? "Online"
                      : pbxStatus === 2
                      ? "Disconnected"
                      : "Unregistered"}
                  </small>
                </div>
                <div className="col-auto">
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={
                      isSubmitting ||
                      !nUrl ||
                      !nApi ||
                      !nPassword ||
                      Object.keys(errors).length > 0
                    }
                  >
                    {isSubmitting ? "Conectando..." : "Connect"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
