import React, { useRef, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCallStatus,
  updateCurrentCall,
} from "../store/features/campaignsLauchedSlice";
import { format } from "date-fns";
/*El componente CallProcessor gestiona las llamadas telefónicas en función de una campaña lanzada. Utiliza varios hooks
(useState, useEffect, useRef, useCallback) para manejar el estado, los índices de las llamadas, y las interacciones con la API de la central telefónica (PBX).

id_launchedCampaing: ID de la campaña lanzada, necesario para gestionar las llamadas asociadas a dicha campaña.
 */
export default function CallProcessor({ id_launchedCampaing }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pbx = useSelector((state) => state.pbx.value);

  const intervalValue = 6;
  const [numLines, setNumLines] = useState(1);
  const [isPaused, setIsPaused] = useState(true);
  const [idCampaignLaunched, setIdCampaignLaunched] = useState(0);

  const [destinatarios, setDestinatarios] = useState([]);
  const [id_campania, setId_campania] = useState();
  const [numeroIvr, setNumeroIvr] = useState();

  const currentIndex = useRef(0);
  const previousIndex = useRef([]);
  const isCancelled = useRef(false);
  const callid = useRef(null);
  const callInCurse = useRef(false);
  const endingCampaing = useRef(false);

  const campaingsLaunched = useSelector(
    (state) => state.campaingsLaunched.value
  );

  useEffect(() => {
    console.log("Este es el Call Processor 2!");
    console.log("ID campania lanzada:", id_launchedCampaing);

    const launchedCampaign = campaingsLaunched.find(
      (campaign) => campaign.idCampaingLunched === id_launchedCampaing
    );

    if (launchedCampaign) {
      console.log(
        "Campaña lanzada encontrada!!! Asignando valores",
        launchedCampaign
      );
      setDestinatarios(launchedCampaign.calls);
      setNumeroIvr(launchedCampaign.ivr_number);
      setId_campania(launchedCampaign.idcampaign);
    } else {
      console.log("Campaña lanzada no encontrada con id:", id_launchedCampaing);
    }
  }, [campaingsLaunched]);

  //console.log("VAlidadn si se actualiza el slice: " + campaingsLaunched.calls)
  const validateErrorCodes = (errorCode) => {
    let describeErrorMesange = "";

    switch (errorCode) {
      case 10004:
        describeErrorMesange =
          "Phone system timeout, please reconnect the phone system.";
        return swal({
          title: "An error occurred",
          text: describeErrorMesange,
          icon: "error",
          buttons: {
            redirect: {
              text: "Ir a Central Telefonica",
              value: "redirect",
            },
            cancel: "Cancel",
          },
        }).then((value) => {
          if (value === "redirect") {
            navigate("/pbx-management");
          }
        });

      default:
        describeErrorMesange = "Unidentified error";
        return swal({
          title: "An error occurred",
          text: describeErrorMesange,
          icon: "error",
        });
    }
  };

  const handleNumLinesChange = (event) => {
    setNumLines(event.target.value);
  };

  const checkCall = useCallback(async () => {
    console.log("Revisando si hay llamada...");

    if (callid.current === null) {
      console.log("Sin llamadas!", callid);
      return;
    }

    console.log("id actual:", callid.current);

    try {
      let response;
      let api =
        pbx.domain + "/openapi/v1.0/call/query?access_token=" + pbx.token;
      if (pbx.model === "S-Series") {
        api = pbx.domain + "/api/v2.0.0/call/query?token=" + pbx.token;
        response = await axios.post(api);
      } else {
        response = await axios.get(api);
      }
      let status, Calls, errmsg, data;
      console.log("Respuesta de trunk:", response);

      if (pbx.model === "S-Series") {
        ({ status, Calls } = response.data);
      } else {
        ({ errmsg, data } = response.data);
      }
      const statuscall = status ? status : errmsg;
      const calldata = Calls ? Calls : data;

      if (statuscall !== "Success" && statuscall !== "SUCCESS") {
        throw new Error(response.data.errmsg || "La respuesta no fue exitosa");
      }

      // Iterate over previousIndex and check if each callid exists in calldata
      // Verificamos si calldata es null
      if (!calldata) {
        // Si es null, despachamos la acción para todos los elementos de previousIndex.current
        previousIndex.current.forEach((callid) => {
          console.log("Callid procesado ya que calldata es null:", callid);
          dispatch(
            updateCallStatus({
              idCampaingLunched: id_launchedCampaing,
              idCall: callid,
              newStatus: "Successful",
            })
          );
        });
        // Borramos todos los elementos de previousIndex.current
        previousIndex.current = [];
      } else {
        // Si calldata no es null, procedemos con el filtrado
        previousIndex.current = await Promise.all(
          previousIndex.current.map(async (callid) => {
            const exists = calldata.some((call) => call.call_id === callid);

            // Si no existe, se despacha la acción para actualizar el estado
            if (!exists) {
              console.log("Callid no encontrado:", callid, calldata);
              await dispatch(
                updateCallStatus({
                  idCampaingLunched: id_launchedCampaing,
                  idCall: callid,
                  newStatus: "Successful",
                })
              );
            }
            return exists ? callid : null;
          })
        ).then((result) => result.filter((id) => id !== null)); // Filtrar los nulls
      }

      if (!Array.isArray(calldata) || calldata.length === 0) {
        callid.current = null;
        callInCurse.current = false;
        setTimeout(() => {}, 2000); // 2 segundos de espera
        return;
      }

      let count = 0;

      console.log("Data:", calldata);

      for (let call of calldata) {
        if (!call || !call.members || !Array.isArray(call.members)) continue;
        for (let member of call.members) {
          if (member.outbound && member.outbound.trunk_name === pbx.trunk) {
            count++;
            console.log("LLamada encontrada en trunk!:", count);
          } else if (
            member.inbound &&
            member.inbound.trunk_name === pbx.trunk
          ) {
            count++;
            console.log("LLamada encontrada en trunk!:", count);
          }
        }
      }

      if (count < numLines) {
        console.log("Puede llamar");
        callInCurse.current = false;
      } else {
        console.log("No puede llamar!");
        callInCurse.current = true;
      }
    } catch (err) {
      console.error("Error en checkCall:", err);
      swal(
        "Ocurrió un error",
        err.message || "Por favor inténtelo más tarde",
        "error"
      );
      setIsPaused(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numLines, pbx]);

  let makeCall = useCallback(
    async (idContact, phoneNumber) => {
      if (callInCurse.current) {
        console.log("Hay una llamada en curso!");
        return;
      }

      console.log("Llamando a un número...");
      try {
        let api_call =
          pbx.domain + "/openapi/v1.0/call/dial?access_token=" + pbx.token;

        let data_call = {
          caller: numeroIvr,
          callee: pbx.ruta + phoneNumber,
          dial_permission: pbx.extension,
          auto_answer: "no",
        };

        if (pbx.model === "S-Series") {
          api_call = pbx.domain + "/api/v2.0.0/call/dial?token=" + pbx.token;
          data_call = {
            caller: numeroIvr,
            callee: pbx.ruta + phoneNumber,
            autoanswer: "no",
          };
        }

        console.log("Control de API:", api_call, data_call);
        const response = await axios.post(api_call, data_call);
        console.log("Respuesta de llamada:", response);

        if (response.data.errmsg === "SUCCESS") {
          //Primero reconocemos y almacenamos los IDs de las llamadas
          if (response.data.call_id) {
            previousIndex.current.push(response.data.call_id); // Usamos push para arrays
          }

          if (response.data.call_id) {
            callid.current = response.data.call_id;
          }

          console.log("Actualizando estado de nueva llamada! Calling");
          // Actualizar solo el destinatario específico
          const newStatus = "Calling";
          if (id_launchedCampaing && idContact >= 0 && callid.current) {
            dispatch(
              updateCurrentCall({
                idCall: callid.current,
                idCampaingLunched: id_launchedCampaing,
                idContact: idContact,
                newStatus,
                date: format(new Date(), "yyyy/MM/dd HH:mm:ss"),
              })
            );
            console.log("Intentando actualizar registro@");
          } else {
            console.log("Los IDs no están definidos:", {
              idCampaingLunched: id_launchedCampaing,
              idCall: callid.current,
              idContact,
            });
          }
        } else {
          const errorCode = response.data.errcode;
          console.error("Error en makeCall:", errorCode);
          validateErrorCodes(errorCode);
          setIsPaused(true);
        }
      } catch (err) {
        console.error("Error en makeCall:", err);
        console.log("Sacando los datos del error" + err.data);
        swal("Ocurrió un error", validateErrorCodes(err.data.errcode), "error");
        setIsPaused(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      pbx.domain,
      pbx.token,
      pbx.ruta,
      pbx.extension,
      pbx.model,
      numeroIvr,
      id_launchedCampaing,
    ]
  );
  
  
  const processCalls = useCallback(async () => {
    console.log("Inciando Ciclo");
    if (currentIndex.current >= destinatarios.length) {
      if (previousIndex.current.length === 0) {
        setIsPaused(true);

        swal("The campaign has been completed", {
          icon: "success",
        });
        return;
      }
      endingCampaing.current = true;
    }

    if (!isPaused && !isCancelled.current) {
      await checkCall();
      if (!callInCurse.current && !endingCampaing.current) {
        const { id, num } = destinatarios[currentIndex.current];
        console.log("Revisando datos para llamar...", currentIndex.current);
        console.log(destinatarios);
        console.log(id, num);
        await makeCall(id, num);
        currentIndex.current++;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinatarios, isPaused]);

  useEffect(() => {
    if (!isPaused && !isCancelled.current) {
      console.log("Estableciendo intervalo de llamadas");
      const interval = setInterval(processCalls, intervalValue * 1000);
      return () => clearInterval(interval);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaused, intervalValue]);

  const handleCampaignAction = async (action) => {
    try {
      if (action === "start") {
        setIdCampaignLaunched(id_campania);
        setIsPaused(false);
        isCancelled.current = false;
      } else if (action === "cancel") {
        swal({
          title: "End campaign",
          text: "Are you sure to finish the campaign?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            isCancelled.current = true;
            setIsPaused(true);
            navigate("/select-campaign");
          }
        });
      } else {
        setIsPaused(action === "pause");
      }
    } catch (err) {
      console.error(`Error en ${action}Campaign:`, err);
      swal("Ocurrió un error", "Por favor inténtelo más tarde", "error");
    }
  };

  return (
    <div className="text-right d-flex flex-wrap justify-content-end align-items-center">
      <form className="mb-2 mr-sm-1 d-flex align-items-center">
        <label className="mr-2 mb-0" style={{ whiteSpace: "nowrap" }}>
        Number of Lines:
        </label>
        <input
          type="number"
          className="form-control form-control-sm mr-2 bg-white border border-dark"
          style={{ width: "70px" }}
          value={numLines}
          onChange={handleNumLinesChange}
          min="1"
        />
      </form>
      <button
        type="button"
        className="btn mb-2 btn-success text-white mr-1"
        disabled={idCampaignLaunched !== 0}
        onClick={() => handleCampaignAction("start")}
      >
        <span className="fe fe-phone-forwarded fe-16 mr-1"></span>
        Start Calls
      </button>
      <button
        className="btn mb-2 btn-warning text-white mr-1"
        disabled={isPaused}
        onClick={() => handleCampaignAction("pause")}
      >
        <span className="fe fe-pause fe-16 mr-1"></span>
        Pause 
      </button>
      <button
        className="btn mb-2 btn-info text-white mr-1"
        disabled={!isPaused}
        onClick={() => handleCampaignAction("resume")}
      >
        <span className="fe fe-play fe-16 mr-1"></span>
        Resume
      </button>
      <button
        className="btn mb-2 btn-danger text-white mr-1"
        onClick={() => handleCampaignAction("cancel")}
      >
        <span className="fe fe-stop-circle fe-16 mr-1"></span>
        Cancel
      </button>
    </div>
  );
}
CallProcessor.propTypes = {
  id_launchedCampaing: PropTypes.number.isRequired,
};

CallProcessor.defaultProps = {
  id_launchedCampaing: 0,
};
