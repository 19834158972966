import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLaunchedCampaingsTable } from "../../store/functions";
/* El componente LaunchedCampaigns muestra una tabla con las campañas lanzadas, permitiendo ver detalles de cada una. Los datos son obtenidos del estado global
 (campaingsLaunched y campaings) a través del store de Redux.

dataSource: Arreglo que contiene la información de las campañas lanzadas.
loading: Indicador de si la tabla está cargando los datos.*/
export default function LaunchedCampaigns() {
  const [dataSource, setDataSource] = useState();
  const navigate = useNavigate();
  //Store
  const campaingsLaunched = useSelector(
    (state) => state.campaingsLaunched.value
  );
  const campaings = useSelector((state) => state.campaings.value);

  const columns = [
    {
      title: "Campain Name",
      dataIndex: "campaing",
      editable: true,
    },
    {
      title: "IVR/Queue Name",
      dataIndex: "ivr_name",
    },
    {
      title: "IVR/Queue Number",
      dataIndex: "ivr_number",
    },
    {
      title: "Launched Date",
      dataIndex: "date_launched",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <button
            type="button"
            className="btn btn-success "
            onClick={() => {
              navigate(`/dashboard/${record.key}`);
            }}
          >
            <span className="fe fe-eye fe-12 mr-2"></span>Show Details
          </button>
        ) : null,
    },
  ];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setDataSource(getLaunchedCampaingsTable(campaingsLaunched, campaings));
    setLoading(false);
  }, [campaingsLaunched, campaings]);

  return (
    <div>
      <Table loading={loading} dataSource={dataSource} columns={columns} />
    </div>
  );
}
LaunchedCampaigns.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      campaing: PropTypes.string.isRequired,
      ivr_name: PropTypes.string,
      ivr_number: PropTypes.string,
      date_launched: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
};
