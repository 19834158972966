import { Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeCampain } from "../../store/features/campaingsSlice";
/*El componente CampaignsTable se encarga de mostrar una tabla con las campañas y la información relacionada a ellas, como el nombre de la campaña, 
nombre y número del IVR, fecha de creación, y fecha del último lanzamiento.

campaings: Estado global que contiene las campañas creadas.
campaingsLaunched: Estado global que contiene las campañas lanzadas.
handleDelete: Función que permite eliminar una campaña tanto del frontend como del backend.
aboutCampaings: Arreglo que asocia las campañas con su última fecha de lanzamiento.
 */
export default function CampaignsTable() {
  const navigate = useNavigate();
  const campaings = useSelector((state) => state.campaings.value);
  const campaingsLaunched = useSelector(
    (state) => state.campaingsLaunched.value
  );
  const dispatch = useDispatch();
  const aboutCampaings = campaings.map((camping) => {
    const matchingLaunchedCampaings = campaingsLaunched.filter(
      (launched) => launched.idcampaign === camping.id
    );

    if (matchingLaunchedCampaings.length > 0) {
      const lastCall =
        matchingLaunchedCampaings[matchingLaunchedCampaings.length - 1];
      return {
        ...camping,
        date_launched: lastCall ? lastCall.date : "The campaign has not yet been launched",
      };
    } else {
      return {
        ...camping,
        date_launched: "The campaign has not yet been launched",
      };
    }
  });
  const handleDelete = (key) => {
    dispatch(removeCampain(key));
  };

  const columns = [
    {
      title: "Campaign name",
      dataIndex: "name",
      editable: true,
    },
    {
      title: "IVR/Queue Name",
      dataIndex: "ivr_name",
    },
    {
      title: "IVR/Queue Number",
      dataIndex: "ivr_number",
    },
    {
      title: "Date of creation",
      dataIndex: "date_creation",
    },
    {
      title: "Date of last release",
      dataIndex: "date_launched",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) =>
        campaings.length >= 1 ? (
          <>
            <button
              type="button"
              className="btn btn-warning mr-3"
              onClick={() => {
                navigate(`/edit-campaign/${record.id}`);
              }}
            >
              Edit
            </button>

            <button
              type="button"
              className="btn btn-success mr-3"
              onClick={() => {
                navigate(`/launch-campaign/${record.id}`);
              }}
            >
              Launch
            </button>

            <Popconfirm
              title="¿Do you want to delete this campaign?"
              onConfirm={() => handleDelete(record.id)}
              okText="Sí"
              cancelText="No"
            >
              <button type="button" className="btn btn-danger">
                Delete
              </button>
            </Popconfirm>
          </>
        ) : null,
    },
  ];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setLoading(false);
  }, []);

  return (
    <div>
      <Table loading={loading} dataSource={aboutCampaings} columns={columns} />
    </div>
  );
}
CampaignsTable.propTypes = {};

CampaignsTable.defaultProps = {};
